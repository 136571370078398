import "./App.css";
import React from "react";
import MainPage from "./components/MainPage";
import Gadget from "./components/Gadget";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage/>,
  },
  {
    path: "/gadget",
    element: <Gadget/>,
  },
]);


const App = () => {
  // let logo = require("./logo.svg");
  return (
    <RouterProvider router={router} />
  ); 
}


export default App;
