import { useRouteMatch, Redirect,useNavigate, useLocation } from "react-router-dom";
import { redirect } from "react-router-dom";


const Gadget = () => {
    const navigate = useNavigate();
    const widget_url = "https://widget.weezevent.com/pay/186860/widgets/38cc46fa-baee-41e3-8c07-e6b9564cb968";
    const on_click1 = () => {
        window.top.location.href = widget_url
    }
    const on_click2 = () => {
        navigate(widget_url);
      };


    const on_click3 = () => {
        redirect(widget_url);
    }
      

    return (
        <div>
            <div onClick={on_click1}>change window.top</div>
            <div onClick={on_click2}>navigate</div>
            <div onClick={on_click3}>redirect</div>
            <a href={widget_url}>simple href</a>
        </div>
    );
  };
export default Gadget;