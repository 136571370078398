import ParticlesBg from "particles-bg";

const MainPage = () => {
    let logo = require("./logo.svg");
    return (
      <div className="mainPage">
        <div className="header">
          <p className="headerLogo">
            <img className="headerLogo" src={logo.default} />
          </p>
          <p className="headerFiller"> </p>
          {/* <p className="headerButton">Skills</p> */}
          <p className="headerButton">
            <a href="mailto:contact@technisys.ch">Contact</a>
          </p>
        </div>
        <ParticlesBg
          type="cobweb"
          bg={true}
          config={{ body: "TEHH", num: 1000 }}
          // color="#f8bdbd"
        />
  
        <div className="firstView">
          <img className="firstViewLogo" src={logo.default} />
          <p className="shortpitch">
            We build features for your company and resolve long term technical
            debt smoothly !
          </p>
        </div>
      </div>
    );
  }
export default MainPage;